import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import { DateContext } from '../../wrappers/DateContext'

import DateOptions from './DateOptions'
import { dateToString, stringToDate } from '../../../utils/dateFormatting'


const DatePickerComponent = ({ setDate, date }) => {
    const [width, setWidth] = useState(window.innerWidth)

    const listener = () => {
        setWidth(window.innerWidth)
    }

    window.addEventListener('resize', listener);

    return (
        <DatePicker
            className="input is-small"
            maxDate={new Date()}
            selected={stringToDate(date)}
            onChange={(value) => {
                const formattedDate = dateToString(value)
                setDate(formattedDate, true)
                return value
            }}
            dateFormat="MM/dd/yyyy"
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={150}
            scrollableYearDropdown
            scrollableMonthDropdown
            autoComplete="off"
            inline={width > 769 ? true : false}
            popperModifiers={{
                offset: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport"
                }
              }}
        />
    )
}

export default () => {
    const {
        selectorVisible,
        toggleSelectorVisible,
        date,
        setDate,
        dateRangeLabel,
        setDateRangeLabel,
    } = useContext(DateContext)

    const [selectedDateRangeLabel, setSelectedDateRangeLabel] = useState(
        dateRangeLabel
    )
    const [selectedDate, setSelectedDate] = useState({
        minDate: date.minDate,
        maxDate: date.maxDate,
    })


    useEffect(() => {
        setSelectedDateRangeLabel(dateRangeLabel)
    }, [dateRangeLabel])

    useEffect(() => {
        setSelectedDate({minDate: date.minDate, maxDate: date.maxDate})
    }, [date])

    const updateDateSelection = (field, value, custom) => {
        if (custom) {
            setSelectedDateRangeLabel('Custom')
        }
        setSelectedDate((state) => ({
            ...state,
            [field]: value,
        }))
    }

    const applyDateSelection = () => {
        setDate(selectedDate)
        setDateRangeLabel(selectedDateRangeLabel)
        toggleSelectorVisible(false)
    }

    return (
        <div className={`dateDrawerWrapper`}>
            <div className={`dateDrawer ${selectorVisible ? '' : 'inactive'}`}>
                <div className="dateSelectorWrapper">
                    <div className="dateSelectorLabelWrapper">
                        <div className="dateSelectorLabel">Start Date:</div>
                    </div>
                    <DatePickerComponent
                        date={selectedDate.minDate}
                        setDate={updateDateSelection.bind(this, 'minDate')}
                    />
                </div>
                <div className="dateSelectorWrapper">
                    <div className="dateSelectorLabelWrapper">
                        <div className="dateSelectorLabel">End Date:</div>
                    </div>
                    <DatePickerComponent
                        date={selectedDate.maxDate}
                        setDate={updateDateSelection.bind(this, 'maxDate')}
                    />
                </div>
                <div className="dateControlsWrapper">
                    <DateOptions
                        setMinDate={updateDateSelection.bind(this, 'minDate')}
                        setMaxDate={updateDateSelection.bind(this, 'maxDate')}
                        dateRangeLabel={selectedDateRangeLabel}
                        setDateRangeLabel={setSelectedDateRangeLabel}
                    />
                    <button
                        className="dateSelectButton button is-small is-right is-primary"
                        onClick={() => applyDateSelection()}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}
