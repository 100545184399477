import React, { useContext } from 'react'
import LandingPageWrapper from '../../elem/landing-page/LandingPageWrapper'
import { UserContext } from '../../wrappers/UserContext'
import withConfig from '../../wrappers/withConfig'

const LoginPage = withConfig(({ config }) => {
    const { client } = useContext(UserContext)
    const { CONFIGURED_URLS, USE_OKTA } = config

    return (
        <LandingPageWrapper>
            <div
                style={{ zIndex: 3 }}
                className="myDashboardsText is-size-4 authenticationText"
            >
                <p>
                    Please{' '}
                    <span
                        className="authenticationLink"
                        onClick={function () {
                            client.login()
                        }}
                    >
                        Login
                    </span>
                    {!USE_OKTA && (
                        <>
                            {' '}
                            or{' '}
                            <a
                                className="authenticationLink"
                                target="_blank"
                                rel="opener"
                                href={CONFIGURED_URLS.newRegistration}
                            >
                                Register
                            </a>
                        </>
                    )}{' '}
                    to access dashboards
                </p>
            </div>
        </LandingPageWrapper>
    )
})

export default LoginPage
