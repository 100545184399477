import React, { useEffect } from 'react'
import { LoginCallback as OktaCallbackComponent } from '@okta/okta-react'
import urls from '../../../utils/constants/urls'
import withConfig from '../../wrappers/withConfig'
import LoadingSpinner from '../../elem/LoadingSpinner'

const OIDCCallbackComponent = () => {
    useEffect(() => {
        const t = setTimeout(() => {
            window.location = urls.home
            return null
        }, 5000)
        return () => clearTimeout(t)
    }, [])

    return (
        <div className="hero is-large">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="title">
                        <div className="callbackSpinnerWrapper">
                            <LoadingSpinner/>
                        </div>
                    </div>
                    <p className="subtitle authorizationMessage">Authorizing...</p>
                </div>
            </div>
        </div>
    )
}

const CallbackComponent = withConfig(({config }) => {
    const { USE_OKTA } = config
    const Component = USE_OKTA ? OktaCallbackComponent : OIDCCallbackComponent
    return <Component />
})

export default CallbackComponent
