import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../wrappers/UserContext'
import { Navigate } from 'react-router-dom'
import { checkRole, getRoles, hasAccessToAdmin } from '../../../utils/user/permissions'
import urls from '../../../utils/constants/urls'
import WithConfig from '../../wrappers/withConfig'

const RestrictedAccess = WithConfig(({ config, allowedRoles, children }) => {
    const { client, roles } = useContext(UserContext)
    const [hasAccess, setHasAccess] = useState(true)
    const { USE_OKTA, USE_AUTHENTICATION } = config

    useEffect(() => {
        const checkClientAccess = async (allowedRoles) => {
            try {
                await client.getUser().then((user) => {
                    const roles = getRoles(user, USE_OKTA)
                    if (allowedRoles.some((role) => checkRole(role, roles)) || hasAccessToAdmin(roles)) {
                        setHasAccess(true)
                    } else {
                        setHasAccess(false)
                    }
                })
            } catch (e) {
                setHasAccess(false)
            }
        }
        if (USE_AUTHENTICATION) {
            checkClientAccess(allowedRoles)
        }
    }, [client, allowedRoles, USE_AUTHENTICATION, USE_OKTA, roles])

    if (hasAccess) {
        return children
    } else {
        return <Navigate to={urls.login} replace />
    }
})

export default RestrictedAccess