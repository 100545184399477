import React, { useEffect, useState, useContext } from 'react'
import EditWidgetForm from './EditWidgetForm'
import toast from '../../../elem/Toast'
import { Navigate } from 'react-router-dom'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import AdminLayout from '../../../elem/admin/AdminLayout'
import withConfig from '../../../wrappers/withConfig'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'

const EditWidget = ({ config }) => {
    const { API_URL } = config
    const [widget, setWidget] = useState(null)
    const [edited, setEdited] = useState(false)
    const { widgetId, setNewObject } = useContext(CurrentDashboardContext)
    const { authenticatedFetch } = useContext(APIRequestContext)

    useEffect(() => {
        authenticatedFetch(`${API_URL}/Widget/${widgetId}?noData`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setWidget(response.data)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Widget Types:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
    }, [API_URL, widgetId])

    if (!widget) {
        return null
    }

    const closeForm = () => {
        setEdited(true)
    }

    if (edited) {
        setNewObject(widget)
        return (
            <Navigate
                to={{
                    pathname: `/admin/dashboard/${widget.DashboardName}/page/${widget.PageId}`,
                }}
            />
        )
    }

    return (
        <div className="editDashboard">
            <AdminLayout title={'Edit Widget'}>
                <EditWidgetForm widget={widget} closeForm={closeForm} />
            </AdminLayout>
        </div>
    )
}

export default withConfig(EditWidget)
