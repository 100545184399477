import React, { useState, useContext } from 'react'
import CreatePageForm from './CreatePageForm'
import { Navigate } from 'react-router-dom'
import AdminLayout from '../../../elem/admin/AdminLayout'
import {CurrentDashboardContext} from '../../../wrappers/CurrentDashboardContext'

const CreatePage = () => {
    const { widgetGroups, currentDashboard, setNewObject } = useContext(CurrentDashboardContext)
    const [pageId, setPageId] = useState(null)

    if (!currentDashboard) {
        return null
    }
    const pages = widgetGroups
    .filter(
        (widget) =>
            widgetGroups.indexOf(widget.PageId) === widgetGroups.lastIndexOf(widget.PageId)
    )
    .map((widget) => ({
        name: widget.PageName,
        id: widget.PageId,
    }))
    
    const closeForm = (response) => {
        setPageId(response)
    }
    
    if (pageId) {
        setNewObject(pageId)
        return (
            <Navigate
                to={{
                    pathname: `/admin/dashboard/${currentDashboard.DashboardName}`,
                }}
            />
        )
    }
    return (
        <AdminLayout title={'Create Page'}>
            <CreatePageForm
                dashboardId={currentDashboard.DashboardId}
                pageIds={pages}
                closeForm={closeForm}
            />
        </AdminLayout>
    )
}

export default CreatePage
