import React, { useContext, useState } from 'react'
import toast from '../../../elem/Toast'
import { useForm } from 'react-hook-form'
import Select from '../../../elem/form/Select'
import WidgetTypes from '../../../elem/admin/SelectWidgetTypes'
import Databases from '../../../elem/admin/SelectDatabases'
import Input from '../../../elem/form/TextInput'
import Checkbox from '../../../elem/form/Checkbox'
import NumberInput from '../../../elem/form/NumberInput'
import MultiSelect from '../../../elem/form/MultiSelect'
import PreviewQueryTable from './PreviewQueryTable'
import withConfig from '../../../wrappers/withConfig'

import { FaEdit, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'

const EditWidgetForm = ({ config, widget, closeForm }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { register, handleSubmit, control, errors, unregister } = useForm({
        mode: 'onChange',
    })
    const { API_URL } = config
    const [type, setType] = useState(widget.WidgetType)
    const [query, setQuery] = useState(null)
    const [string, setString] = useState(null)
    const [preview, setPreview] = useState(null)
    const functionOptions = [
        { value: 'COUNT', label: 'Count (COUNT)' },
        { value: 'SUM', label: 'Sum (SUM)' },
        { value: 'AVG', label: 'Average (AVG)' },
        { value: 'MIN', label: 'Minimum (MIN)' },
        { value: 'MAX', label: 'Maximum (MAX)' },
    ]

    const sortOptions = [
        { value: 'ASC', label: 'Ascending (ASC)'},
        { value: 'DESC', label: 'Descending (DESC)'},
        { value: '', label: 'NULL'}
    ]

    const [editing, setEditing] = useState(false)

    const deleteKeys = (object) => {
        Object.keys(object).forEach((key) => {
            if (object[key] === null && key !== 'NumRows') {
                object[key] = ''
            }
        })
        return object
    }

    const [state, setState] = useState(deleteKeys(widget))

    const initialFunction = functionOptions.filter(
        (option) => option.value === state.AggregateFunction
    )[0]

    const initialSortDirection = sortOptions.filter(
        (option) => option.value === state.DefaultSortDirection
    )[0]

    let formClass = editing ? 'button is-info is-small' : 'hidden'
    let textClass = editing ? 'hidden' : 'button is-info is-small'

    const POST = (widget) => {
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(widget),
        }
    }

    const PUT = (page) => {
        return {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(page),
        }
    }

        const generateQueryString = (data) => {
            authenticatedFetch(`${API_URL}/widget/generate`, POST(data))
                .then(async (response) => {
                    const responseText = await response.text()
                    if (response.ok) {
                        return responseText
                    } else {
                        throw new Error(responseText)
                    }
                })
                .then((response) => {
                    setString(response)
                    setQuery(response)
                })
                .catch((e) => {
                    setString(e.message)
                })
        }

    const previewQuery = (queryString) => {
        setPreview(null)
        authenticatedFetch(`${API_URL}/widget/preview`, POST(queryString))
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setPreview(response.data)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Create Widget:' +
                        (e.message ? e.message : 'Query preview failed'),
                })
            })
    }

    const editWidget = (editedWidget) => {
        authenticatedFetch(
            `${API_URL}/admin/widget/edit/${widget.WidgetId}`,
            PUT(editedWidget)
        )
            .then(async (response) => {
                if (response.ok) {
                    return
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Edit Dashboard:' +
                        (e.message ? e.message : 'Dashboard edit failed'),
                })
            })
            .then(() => {
                setState(editedWidget)
                setEditing(false)
                closeForm(editedWidget)
            })
    }

    const handleSelectChange = (event) => {
        if (event[1].name === 'WidgetType') {
            setType(event[0].value)
        }
        setState(s => ({ ...s, [event[1].name]: event[0].value }))
        return event
    }

    const handleMultiChange = (event) => {
        event[0]
            ? setState(s => ({
                  ...s,
                  [event[1].name]: event[0]
                      .map((x) => x.value)
                      .toString()
                      .replaceAll(',', '|'),
              }))
            : setState(s => ({ ...s, [event[1].name]: null }))
        return event[0]
    }

    const formChange = (event) => {
        if (event.target.name !== '') {
            setState({
                ...state,
                [event.target.name]: event.target.value,
            })
        }
        if (event.target.type === 'checkbox') {
            setState({
                ...state,
                [event.target.name]: event.target.checked,
            })
        }
        if (event.target.name === 'NumRows') {
            setState({
                ...state,
                NumRows:
                    event.target.value !== ''
                        ? Number(event.target.value)
                        : null,
            })
        }
    }

    const handleClick = () => {
        const queryString = {
            TargetDatabase: state.TargetDatabase,
            QueryString: string,
        }
        previewQuery(queryString)
    }

    const trimData = (data) => {
        Object.keys(data).map((key) =>
            typeof data[key] === 'string'
                ? (data[key] = data[key].replace(/\s\s+/g, ' ').trim())
                : data[key]
        )
        return data
    }

    const onSubmit = (data) => {
        editWidget(trimData(state))
    }

    const cancel = () => {
        setState(widget)
        setEditing(false)
    }

    const validateOptions = (value) => {
        try {
            JSON.parse(value)
            return true
        } catch {
            return false
        }
    }

    return (
        <>
            <form
                className="form"
                onChange={formChange}
                onSubmit={handleSubmit(onSubmit)}
                onBlur={()=> generateQueryString(state)}
            >
                <div className="buttons">
                    <button
                        type="button"
                        onClick={() => setEditing(true)}
                        className={textClass}
                    >
                        <FaEdit />
                    </button>
                    <button
                        type="button"
                        onClick={cancel}
                        className={formClass}
                    >
                        <FaTimesCircle />
                    </button>
                    <button type="submit" className={formClass}>
                        <FaCheckCircle />
                    </button>
                </div>
                <div className="formContent">
                    <Input
                        label="Widget Name"
                        name="WidgetName"
                        active={editing}
                        defaultValue={
                            state && state.WidgetName ? state.WidgetName : null
                        }
                        register={register}
                        registerParams={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                            maxLength: {
                                value: 200,
                                message:
                                    'The name may not exceed 200 characters.',
                            },
                            pattern: {
                                value: /^([a-zA-Z]+\s)*[0-9a-zA-Z\sd-]+$/,
                                message:
                                    'The name may not contain special characters besides space or -',
                            },
                        }}
                        errors={errors}
                        // helper="This is the name of the Widget - it will be visible to the user, and should be something that helps identify the data contained within the Widget."
                    />
                    <WidgetTypes
                        initialValue={{
                            label: state.WidgetType,
                            value: state.WidgetType,
                        }}
                        control={control}
                        onChange={handleSelectChange}
                        errors={errors}
                        disabled={!editing}
                    />
                    <Input
                        label="Description"
                        name="Description"
                        active={editing}
                        defaultValue={
                            state && state.Description
                                ? state.Description
                                : null
                        }
                        register={register}
                        errors={errors}
                        // helper="An optional description of the Widget."
                        // example="Displays top 10 permit counts by district, county, or agency."
                    />

                    <Databases
                        disabled={!editing}
                        initialValue={
                            state && state.TargetDatabase
                                ? {
                                      label: state.TargetDatabase,
                                      value: state.TargetDatabase,
                                  }
                                : null
                        }
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                        }}
                        onChange={handleSelectChange}
                        errors={errors}
                    />

                    <Input
                        label="Query"
                        name="Query"
                        active={editing}
                        register={register}
                        defaultValue={state && state.Query ? state.Query : null}
                        registerParams={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                            pattern: {
                                value: /^(?!.*?\b(create|drop|alter|insert|delete|update)\b)/,
                                message:
                                    'Query may not include the words create, drop, alter, insert, delete, or update',
                            },
                        }}
                        errors={errors}
                        // helper="The SQL query that determines the data to be exposed to the Widget. There are several rules for the construction of the query: "
                        // example="1) The column defined in ‘Value Label’ must be selected in the query. 2) All columns defined in ‘Group By’ must also be selected in the query. 3) Additionally, if there is a column defined in ‘Aggregate Column’, this must be selected in the query. 4) For all Widget Types except Number, 2DFeatureMap and 3DFeatureMap, a column titled ‘Date’ must be selected in your query."
                    />
                    <Checkbox
                        label="Apply Date Filter"
                        name="ApplyDateFilter"
                        defaultChecked={state.ApplyDateFilter}
                        disabled={true}
                        // helper="The date filter is determined on the page level. To edit this, please edit the page."
                    />
                    <Checkbox
                        label="Apply Filters to Base Query"
                        name="ApplyFiltersToBaseQuery"
                        defaultChecked={
                            state && state.ApplyFiltersToBaseQuery
                                ? state.ApplyFiltersToBaseQuery
                                : null
                        }
                        disabled={!editing}
                        register={register}
                        // helper="If you wish for the date selection + aggregate functions to be applied before selection in the SQL query above, rather than after the selection, check this box."
                        // example="Say the sql query is: ‘SELECT * FROM Production’. If this box is unchecked (the default state), the final widget query might look like ‘SELECT Agency, COUNT(*) as Value FROM (SELECT * FROM Permits) bq GROUP BY Agency WHERE [Date] < 1/1/2012’. When the box is checked, the final widget query would look like: ‘SELECT * FROM (SELECT Agency, COUNT(*) as Value FROM Permits GROUP BY Agency WHERE [Date] < 1/1/2012) bq’."
                    />

                    <NumberInput
                        label="Number of Rows"
                        name="NumRows"
                        active={editing}
                        className={type !== 'Grid' ? 'hidden' : null}
                        defaultValue={
                            state && state.NumRows ? state.NumRows : null
                        }
                        register={register}
                        // helper="If you would like display only a certain number of rows on the Grid, please enter that number here."
                        // example="For example, enter '10' for a Widget that has generates a top-ten list."
                    />
                    <Input
                        label="Widget Options"
                        name="WidgetOptions"
                        active={editing}
                        className={
                            !type.match(/2DFeatureMap|3DFeatureMap|Grid/)
                                ? 'hidden'
                                : null
                        }
                        defaultValue={
                            state && state.WidgetOptions
                                ? state.WidgetOptions
                                : null
                        }
                        register={
                            type.match(/2DFeatureMap|3DFeatureMap|Grid/)
                                ? register
                                : unregister(['WidgetOptions'])
                        }
                        registerParams={
                            type.match(/2DFeatureMap|3DFeatureMap/)
                                ? {
                                      required: {
                                          value: true,
                                          message: 'This field is required.',
                                      },
                                      validate: validateOptions,
                                  }
                                : null
                        }
                        errors={errors}
                    //     helper="Stringified json definitions of layer stylings for 2d + 3d feature maps."
                    //     example="For 2D feature map, shape should be:
                    // '{“layers”: [{“layerName”: “<layername1>”, “style”: {“fillColor”: “fillColor1”, “strokeColor”: “strokeColor1”, “zIndex”: “zIndex1”}}, {“layerName”: “<layername2>”, “style”: {“fillColor”: “fillColor2”, “strokeColor”: “strokeColor2”, “zIndex”: “zIndex2”}, …]}'
                    // For 3DFeatureMap, shape should be:
                    // '{“layers”: [{“layerName”: “<layername1>”, “style”: {“color”: “color1”, “width”: “width1”, “zIndex”: “zIndex1”}}, {“layerName”: “<layername2>”, “style”: {“color”: “color2”, “width”: “width2”, “zIndex”: “zIndex2”}, …]}'"
                    />
                    <Input
                        label="Value Label"
                        name="ValueLabel"
                        active={editing}
                        defaultValue={
                            state && state.ValueLabel ? state.ValueLabel : null
                        }
                        register={
                            !type.match(/Form|Grid|2DFeatureMap|3DFeatureMap/)
                                ? register
                                : unregister(['ValueLabel'])
                        }
                        registerParams={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                        }}
                        errors={errors}
                        // helper="The value label determines what column the Widget data is evaluating. Think of it as the label on the Y axis of a graph."
                        // example="Ff displaying a Grid that wishes to inspect the count of Permits grouped on District or County, the Value Label should be Permits."
                    />
                    <Input
                        label="Default Sort Column"
                        name="DefaultSort"
                        active={editing}
                        defaultValue={
                            state && state.DefaultSort ? state.DefaultSort : null
                        }
                        register={
                            type.match(/Grid/)
                                ? register
                                : unregister(['DefaultSort'])
                        }
                        registerParams={{}}
                        errors={errors}
                    />
                    <Select
                        label="Default Sort Direction"
                        name="DefaultSortDirection"
                        disabled={!editing}
                        className={
                            type.match(/Grid/)
                                ? null
                                : 'hidden'
                        }
                        initialValue={
                            initialSortDirection
                                ? initialSortDirection
                                : { value: 'ASC', label: 'Ascending (ASC)' }
                        }
                        control={control}
                        options={sortOptions}
                        errors={errors}
                        onChange={handleSelectChange}
                        // helper="The SQL-compliant Aggregate Function that accompanies the Group By clause. See https://www.sqlservertutorial.net/sql-server-aggregate-functions/ for more details."
                    />
                    <MultiSelect
                        label="Group By"
                        name="GroupBy"
                        disabled={!editing}
                        initialValue={
                            state && state.GroupBy
                                ? state.GroupBy.split('|').map((item) => ({
                                      label: item,
                                      value: item,
                                  }))
                                : null
                        }
                        value={state && state.GroupBy ? state.GroupBy.split('|').map((item) => ({
                            label: item,
                            value: item,
                        })) : null}
                        className={
                            type.match(/Form|2DFeatureMap|3DFeatureMap/)
                                ? 'hidden'
                                : null
                        }
                        control={control}
                        rules={
                            type.match(/BarChart|ThemeMap/)
                                ? {
                                      required: {
                                          value: true,
                                          message: 'This field is required.',
                                      },
                                  }
                                : null
                        }
                        errors={errors}
                        onChange={handleMultiChange}
                        // helper="The type of the Widget. This determines the format in which the Widget data is displayed."
                        // example="if you wish to see a Grid that has counts of Permits which can be grouped by District, County, or Township, District + County + Township should each be entered as individual options in this field."
                    />
                    <Select
                        label="Aggregate Function"
                        name="AggregateFunction"
                        disabled={!editing}
                        className={
                            type.match(/Form|2DFeatureMap|3DFeatureMap/)
                                ? 'hidden'
                                : null
                        }
                        initialValue={
                            initialFunction
                                ? initialFunction
                                : { value: 'COUNT', label: 'Count (COUNT)' }
                        }
                        control={control}
                        options={functionOptions}
                        rules={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                        }}
                        errors={errors}
                        onChange={handleSelectChange}
                        // helper="The SQL-compliant Aggregate Function that accompanies the Group By clause. See https://www.sqlservertutorial.net/sql-server-aggregate-functions/ for more details."
                    />
                    <MultiSelect
                        label="Feature Files"
                        name="FeatureFiles"
                        initialValue={
                            state && state.FeatureFiles
                                ? state.FeatureFiles.split('|').map((item) => ({
                                      label: item,
                                      value: item,
                                  }))
                                : null
                        }
                        value={state && state.FeatureFiles ? state.FeatureFiles.split('|').map((item) => ({
                            label: item,
                            value: item,
                        })) : null}
                        disabled={!editing}
                        control={control}
                        className={type !== 'ThemeMap' ? 'hidden' : null}
                        rules={
                            type === 'ThemeMap'
                                ? {
                                      required: {
                                          value: true,
                                          message: 'This field is required.',
                                      },
                                  }
                                : null
                        }
                        onChange={handleMultiChange}
                        errors={errors}
                        // helper="This is the name of the .json file in the /public directory that contains the GEOJSON features that you wish to associate with the values returned by the Widget Query (in order to create a theme map). There should be exactly one file for every value in the Group By field, and the files should be in the same order listed as Group By."
                        // example="We wish to visualize Permit count over Districts or Counties. Group By has values 1) District and 2) County. We have districts.json and counties.json as the corresponding GEOJSON files. So the values in this field should be 1) districts.json and 2) counties.json. If you do not know the names of the .json files associated with different geographical features, or wish to request one, please contact your system administrator."
                    />
                    <Input
                        label="Aggregate Column"
                        name="AggregateColumn"
                        active={editing}
                        className={
                            type.match(/Form|2DFeatureMap|3DFeatureMap/)
                                ? 'hidden'
                                : null
                        }
                        defaultValue={
                            state && state.AggregateColumn
                                ? state.AggregateColumn
                                : null
                        }
                        register={register}
                        errors={errors}
                        // helper="The column of the query to which the SQL Aggregate Function is applied."
                        // example="if you wish to display a Sum of Oil Released over the last 5 years, the Aggregate Function would be “Sum”, and the Aggregate Column would be “Oil Released”"
                    />
                    {string ? `Query String: ${string}` : null}

                    <div className="buttons">
                        <button
                            className="button is-info"
                            disabled={!query}
                            onClick={handleClick}
                            type="button"
                        >
                            Preview Query
                        </button>
                    </div>
                </div>
            </form>
            {preview ? <PreviewQueryTable data={preview} /> : null}
        </>
    )
}

export default withConfig(EditWidgetForm)
