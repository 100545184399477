import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CurrentDashboardContextProvider from './components/wrappers/CurrentDashboardContext'
import DateContextProvider from './components/wrappers/DateContext'
import DashboardContextProvider from './components/wrappers/DashboardContext'
import LandingPage from './components/features/landing-page/LandingPage'
import AdminDashboard from './components/features/admin/AdminDashboard'
import CreateDashboard from './components/features/admin/dashboard/CreateDashboard'
import EditDashboard from './components/features/admin/dashboard/EditDashboard'
import CreatePage from './components/features/admin/page/CreatePage'
import EditPage from './components/features/admin/page/EditPage'
import Header from './components/elem/Header'
import SideNav from './components/features/navigation/SideNav'
import TopBar from './components/elem/TopBar'
import DateSelector from './components/elem/date/DateSelector'
import DashboardPanel from './components/features/panel/DashboardPanel'
import { Toast as ToastContainer } from './components/elem/Toast'
import DeleteItem from './components/features/admin/DeleteItem'
import CreateWidget from './components/features/admin/widget/CreateWidget'
import EditWidget from './components/features/admin/widget/EditWidget'
import PageLayoutEditor from './components/features/admin/widget/PageLayoutEditor'
import ErrorBoundary from './components/features/errors/ErrorBoundary'
import PageNotFound from './components/features/errors/PageNotFound'
import ColorContextProvider from './components/wrappers/ColorContext'
import UserContextProvider from './components/wrappers/UserContext'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'
import CallbackComponent from './components/features/user/CallbackComponent'
import urls from './utils/constants/urls'
import LoginPage from './components/features/user/LoginPage'
import RestrictedAccess from './components/features/user/RestrictedAccess'

export default () => (
    <React.Fragment>
        <Header />
        <div className="pageContent">
            <Router>
                <ErrorBoundary>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            <Routes>
                                <Route
                                    exact
                                    path={urls.userCallback}
                                    element={<CallbackComponent />}
                                />
                                <Route
                                    exact
                                    path={urls.login}
                                    element={<LoginPage />}
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <DashboardContextProvider>
                                                <AdminDashboard />
                                            </DashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    path="/delete"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <DeleteItem />
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    path="/admin/dashboard/create"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CreateDashboard />
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <EditDashboard />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/create"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <CreatePage />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <EditPage />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId/layout"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <DateContextProvider>
                                                    <ColorContextProvider>
                                                        <PageLayoutEditor />
                                                        <TopBar />
                                                        <DateSelector />
                                                    </ColorContextProvider>
                                                </DateContextProvider>
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId/widget/create"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <CreateWidget />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId/widget/:widgetId"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={['Admin']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <EditWidget />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path={
                                        '/:dashboardName/:pageName?/:detailKey?'
                                    }
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={[
                                                'Admin',
                                                'Dashboard',
                                            ]}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <DateContextProvider>
                                                    <SideNav />
                                                    <TopBar />
                                                    <DateSelector />
                                                    <ColorContextProvider>
                                                        <DashboardPanel />
                                                    </ColorContextProvider>
                                                </DateContextProvider>
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route
                                    exact
                                    path="/"
                                    element={
                                        <RestrictedAccess
                                            allowedRoles={[
                                                'Admin',
                                                'Dashboard',
                                            ]}
                                        >
                                            <DashboardContextProvider>
                                                <LandingPage />
                                            </DashboardContextProvider>
                                        </RestrictedAccess>
                                    }
                                />
                                <Route element={PageNotFound} />
                            </Routes>
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </ErrorBoundary>
            </Router>
        </div>
        <ToastContainer />
    </React.Fragment>
)
