export default [
    'ID',
    'Name',
    'API 14',
    'OperatorKey',
    'Operator Number',
    'API No',
    'API #',
    'Operator',
    'Permit #',
    'Lease Name', 
    'Entity Name',
    'Guarantor Bond #',
    'Guarantor',
    'Organization Name',
    'Primary Phone',
    'Primary Address',
    "Primary Email",
    'Bond Amount',
    'Active Bond Total',
    'Active Bond Amount',
    'Well Name',
    'Incident',
    'Postal Code',
    'Lease',
    "Well Type",
    "Producing Geological Formation",
    "Surface Managing Entity",
    "Subsurface Managing Entity",
    "Tribe",
    "Pre-Plugging Methane Emissions (grams/hr)",
    "Post-Plugging Methane Emissions (grams/hr)",
    "Methane Measurement Method",
    "Habitat Restored (acres)",
    "US Well ID/API",
    "Surface Managing Entity Name",
    "Subsurface Managing Entity Name",
    "County",
    "Latitude",
    "Longitude",
    "Location Accuracy",
    "Date Restoration Complete",
    "Surface Water Remediation Completion Date",
    "Groundwater Remediation Completion Date",
    "Total Cost of Complete P&A ($ USD)",
    "Fiscal Year",
    "Proposal Response Total ($)",
    "Un-Invoiced Work Total ($)",
    // "Awarded Jobs Saved",
    // "Awarded Jobs Created",
    // "Jobs Saved",
    // "Jobs Created",
    "At-Risk Wells",
    "Witness Date",
    "Restoration Endpoint",
    "Surface Water Contamination Indicators",
    "Surface Water Remediation Method",
    "Groundwater Contamination Indicators",
    "Groundwater Remediation Method",
    "Community Impact Indicators",
    "Witness Name & Certification",
]