import React, { useEffect, useCallback, createContext, useState, useMemo } from 'react'
import { AuthProvider, useAuth, UserManager } from 'oidc-react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Security, useOktaAuth } from '@okta/okta-react'
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js'
import { getRoles } from '../../utils/user/permissions'
import withConfig from '../wrappers/withConfig'
// import settings from '../../utils/constants/userSettings'

const UserContext = createContext(null)

const AppUserContextProvider = withConfig(({ loadingUser, client, authState, config, children }) => {
    const [roles, setRoles] = useState("")
    const [ user, setUser ] = useState(null)
    const { USE_OKTA } = config

    const checkTokenExpiration = useCallback(async () => {
        const now = dayjs()
        // expiration token / refresh handled differently with okta api than oidc default
        if (USE_OKTA) {
            const tokens = await client.tokenManager.getTokens()
            if (tokens && tokens.idToken) {
                const expiresAt = tokens.idToken.expiresAt
                const expiryTimeRemaining = user ? dayjs(expiresAt * 1000).diff(now, 'minute') : Infinity
                if (expiryTimeRemaining < 1) {
                    try {
                        client.logout()
                    } catch (e) {
                        console.log('UserContext: could not log out.')
                    }
                }
            }
            else {
                client.logout()
                console.log('logging user out')
            }
        } else {
            // refresh token + reset info 10 minutes before the user token expires
            const expiryTimeRemaining = user ? dayjs(user.expires_at * 1000).diff(now, 'minute') : Infinity
            if (expiryTimeRemaining < 1) {
                try {
                    const u = await client.signinSilent(user.refresh_token)
                    setUser(u)
                } catch (e) {
                    console.log('UserContext: could not update user expiry')
                }
            }
        }        
    }, [user, client, USE_OKTA])

    // look at the token expiration and logout when expiration time is less than 1 minute
    useEffect(() => {
        // check every 3 seconds
        const interval = setInterval(() => {
            checkTokenExpiration()
        }, 3000)
        // clear the interval when the user changes or the page is refreshed
        return () => {
            clearInterval(interval)
        }
    }, [checkTokenExpiration])

    // when the user changes, update roles list
    useEffect(() => {
        setRoles(getRoles(user, USE_OKTA))
    }, [user, USE_OKTA, client])

    useEffect(() => {
        const getUser = async () => {
            try {
                const u = await client.getUser()
                setUser(u)
            } catch (e) {
                console.log('UserContext: Error getting user -- ', e)
            }
        }
        if (client) {
            getUser()
        }
    }, [client, USE_OKTA, authState])

    
    return (
        <UserContext.Provider
            value={{
                user,
                roles,
                client,
                loadingUser,
            }}
        >
            {children}
        </UserContext.Provider>
    )
})

const OIDCClientProvider = (({loadingUser, children}) => {
    const { userManager: client } = useAuth()
    if (client) {
        client.login = client.signinRedirect
        client.logout = client.signoutRedirect
    }
    return (
        <AppUserContextProvider client={client} loadingUser={loadingUser}>
            {children}
        </AppUserContextProvider>
    )
})

const OIDCContextProvider = withConfig(({ config, children }) => {
    
    const settings = {
        authority: config.REACT_APP_OIDC_AUTHORITY,
        client_id: config.REACT_APP_OIDC_CLIENT_ID,
        client_secret: config.REACT_APP_OIDC_CLIENT_SECRET,
        redirect_uri: config.REACT_APP_OIDC_CLIENT_REDIRECT_URI,
        silent_redirect_uri: config.REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI,
        response_type: config.REACT_APP_OIDC_RESPONSE_TYPE,
        scope: config.REACT_APP_OIDC_SCOPE,
        post_logout_redirect_uri: config.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
        automaticSilentRenew: true
        // loadUserInfo: true
    }
    
    const userManager = new UserManager(settings)
    return (
        <AuthProvider
            userManager={userManager}
            autoSignIn={false}
        >
            <OIDCClientProvider>
                {children}
            </OIDCClientProvider>
        </AuthProvider>
    )
})

const OktaClientProvider = (({config, children}) => {
    const { oktaAuth: client, authState } = useOktaAuth()
    if (client) {
        client.login = client.signInWithRedirect
        client.logout = async () => {
            await client.signOut({ revokeAccessToken: false, postLogoutRedirectUri: config.postLogoutRedirectUri})
            client.tokenManager.clear()
        }
    }
    return (
        <AppUserContextProvider client={client} authState={authState}>
            {children}
        </AppUserContextProvider>
    )
})

const OktaContextProvider = withConfig(({config, children}) => {
    const oktaConfig = useMemo(() => ({
        oidc: {
            clientId:  config.REACT_APP_OIDC_CLIENT_ID,
            issuer:  config.REACT_APP_OIDC_ISSUER,
            redirectUri:  config.REACT_APP_OIDC_CLIENT_REDIRECT_URI,
            scopes: config.REACT_APP_OIDC_SCOPE.split(' '),
            postLogoutRedirectUri: config.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
            pkce: false
          },
          resourceServer: {
            messagesUrl: 'http://localhost:8000/api/messages',
          },
          app: {
            basename: 'https://localhost:3000/',
          },
    }), [config])

    const oktaAuth = useMemo(() => new OktaAuth(oktaConfig.oidc), [oktaConfig])

    const navigate = useNavigate()

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
    }
    return (
        <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
        >
            <OktaClientProvider config={oktaConfig.oidc}>
                {children}
            </OktaClientProvider>
        </Security>
    )
})

const UserContextProvider = withConfig(({config, children}) => {
    const { USE_OKTA } = config
    const Provider = USE_OKTA ? OktaContextProvider : OIDCContextProvider
    return (
        <Provider>
            {children}
        </Provider>
    )
})
export { UserContext }
export default UserContextProvider

