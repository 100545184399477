const submitterRegex = new RegExp('Submitter_([A-Za-z]+)')

const getRoles = (user, USE_OKTA) => {
    if (USE_OKTA) {
        if (user && user.groups) {
            const roles = user.groups
            if (Array.isArray(roles)) {
                return roles.join(',')
            } else {
                return roles
            }
        }
    } else {
        if (user && user.profile && user.profile.role) {
            const roles = user.profile.role
            if (Array.isArray(roles)) {
                return roles.join(',')
            } else {
                return roles
            }
        } else {
            return ''
        }
    }
}

const checkRole = (role, roles) => {
    if (roles && role) {
        return roles.toLowerCase().includes(role.toLowerCase())
    } else {
        return false
    }
}

const getAgencyCode = (roles) => {
    if (roles && isSubmissionRole(roles)) {
        const submitterRole = roles.match(submitterRegex)
        if (submitterRole && submitterRole.length === 2) {
            return submitterRole[1]
        }
        return submitterRole
    } else {
        return null
    }
}

const isSubmissionRole = roles => {
        if (roles) {
            return !!roles.includes('Submitter')
        }
        return false
}

const isReviewerRole = roles => {
    if (roles) {
        return !!roles.includes('Form_Reviewer')
    }
    return false
}

const hasPrintAccess = roles => {
    if (roles) {
        return !!roles.includes('Print')
    }
    return false
}


const hasAccessToManageSampleResults = roles =>
    checkRole('Industry', roles) ||
    checkRole('DataEntry', roles) ||
    checkRole('Form_Reviewer', roles) ||
    isSubmissionRole(roles)

const hasAccessToAdmin = roles => checkRole('Admin', roles)
const hasAccessToDashboard = roles => checkRole('Dashboard', roles)

const hasAccessToAgency = roles => checkRole('Agency', roles)

const hasAccessToPWS = roles => checkRole("PWSAccess", roles)

export {
    getRoles,
    checkRole,
    hasAccessToManageSampleResults,
    hasAccessToAdmin,
    hasAccessToAgency,
    getAgencyCode,
    isReviewerRole,
    hasAccessToPWS,
    hasPrintAccess,
    hasAccessToDashboard
}
