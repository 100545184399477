import React, { useContext } from 'react'
import { hasAccessToAdmin } from '../../../utils/user/permissions'
import { UserContext } from '../../wrappers/UserContext'
import LogoutButton from '../user/LogoutButton'
import ManageUsersButton from '../user/ManageUsersButton'
import AdminLink from './AdminLink'
import withConfig from '../../wrappers/withConfig'

const LandingPageNav = withConfig(({ config }) => {
    const { roles } = useContext(UserContext)
    const { USE_OKTA, DISPLAY_ADMIN } = config
    return (
        <div className="navbar landingNav">
            <div className="navbar-end">
                {DISPLAY_ADMIN && hasAccessToAdmin(roles) ? (
                    <div className="navbar-item">
                        <AdminLink />
                    </div>
                ) : null}
                {!USE_OKTA && hasAccessToAdmin(roles) ? (
                    <div className="navbar-item">
                        <ManageUsersButton />
                    </div>
                ) : null}
                <div className="navbar-item">
                    <LogoutButton />
                </div>
            </div>
        </div>
    )
})

export default LandingPageNav
