import React, { useContext, useMemo } from 'react'
import Particles from 'react-particles-js'
import { UserContext } from '../../wrappers/UserContext'
import particleConfig from './particleConfig'
import LandingPageNav from './LandingPageNav'
import { hasAccessToAdmin, hasAccessToDashboard } from '../../../utils/user/permissions'
import withConfig from '../../wrappers/withConfig'

const LandingPageWrapper = withConfig(({config, children}) => {
    const { user, roles, client } = useContext(UserContext)
    const { USE_OKTA } = config
    const displayLoginFeatures = useMemo(() => {
        if (USE_OKTA) {
            return !!(roles && (hasAccessToAdmin(roles) || hasAccessToDashboard(roles)))
        } else {
            return !!user
        }
    }, [USE_OKTA, roles, user, client])

    return (
        <>
            <Particles className={`particlesWrapper ${displayLoginFeatures ? '' : 'login'}`} params={particleConfig} />
            {displayLoginFeatures && <LandingPageNav />}
            <div className="landingPageWrapper">
                <div className="landingPage">
                    <div className="landingPageBanner">
                        <div className="landingPageBannerText is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                            Arizona
                        </div>
                        <div className="landingPageBannerText is-size-3-desktop is-size-4-tablet is-size-5-mobile">
                            RBDMS Dashboard
                        </div>
                    </div>
                    <div className={`landingPageContent ${displayLoginFeatures ? '' : 'short'}`}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
})

export default LandingPageWrapper
