import React, { useContext, useRef, useState, useEffect } from 'react'
import Map from 'ol/Map'
// import { defaults as defaultInteractions } from 'ol/interaction'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import { unByKey } from 'ol/Observable'
import { ZoomToExtent } from 'ol/control'

import getLayers, { getMinimumLayerExtent } from './getLayers'

import withConfig from '../../../../wrappers/withConfig'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'
import { CurrentDashboardContext } from '../../../../wrappers/CurrentDashboardContext'
import MapContextProvider, { MapContext } from '../MapContext'
import { displayPopup, getSelectControl, zoomControls } from '../mapConfig'
import MapTooltip from '../Tooltip'
import MapLegend from './MapLegend'
import { getLegendDataFromLayers } from './styleFunctions'

const getTooltipDataFromFeature = (tooltipState) => {
    if (tooltipState) {
        const { feature } = tooltipState
        const data = feature ? feature.get('data') : []
        if (data) {
            return Object.keys(data).map(d => ({
                field: d,
                value: data[d]
            }))
        }
    }
    return []
}

const FeatureMap = withConfig(({ config, loading, layers }) => {
    const { dashboardName, expandedWidgetId, sharedPageKey, sharedPageId, setSharedPageId } = useContext(CurrentDashboardContext)
    const { legendState } = useContext(MapContext)

    // get map config associated w/ the dashboard name
    const { MAP: mapConfig } = config
    const { INITIAL_ZOOM_LEVEL, CENTER_LAT_LNG, MAX_ZOOM_LEVEL } = mapConfig[dashboardName]

    // create internal state + refs
    const mapRef = useRef(null)
    const mapHeightRef = useRef(null)
    const [map, setMap] = useState(null)
    const [popupKey, setPopupKey] = useState(null)
    const [eventKeys, setEventKeys] = useState([])
    const [tooltipState, setTooltipState] = useState(null)
    const selectControl = getSelectControl()

    // on load, set up the map
    useEffect(() => {
        const map = new Map({
            target: mapRef.current,
            controls: zoomControls(null),
            // interactions: defaultInteractions({ mouseWheelZoom: false }),
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                projection: 'EPSG:4326',
                center: CENTER_LAT_LNG,
                zoom: INITIAL_ZOOM_LEVEL,
                maxZoom: MAX_ZOOM_LEVEL ? MAX_ZOOM_LEVEL : null
            })
        })

        // set initial extent to that of the view
        const initialExtent = map.getView().calculateExtent()
        map.getControls().getArray().find(x => x instanceof ZoomToExtent).extent = initialExtent
        setMap(map)
    }, [ 
        CENTER_LAT_LNG, 
        INITIAL_ZOOM_LEVEL,
        MAX_ZOOM_LEVEL
    ])

    useEffect(() => {
        if (map && mapRef) {
            map.setSize(mapRef.current.clientWidth, mapRef.current.clientHeight)
            map.updateSize()
        }
    }, [loading, map, mapRef, expandedWidgetId])

    // add tooltip event listeners when map loads
    useEffect(() => {
        if (map) {
            if (!popupKey) {
                const key = map.on('pointermove', (e) =>
                    displayPopup(e, map, setTooltipState)
                )
                setPopupKey(key)
            }
        } else {
            if (popupKey) {
                unByKey(popupKey)
                setPopupKey(null)
            }
        }
    }, [map, popupKey])
    
    // on layers update, add layers to map
    // and set the extent to the largest of the layers
    useEffect(() => {
        if (layers.length && map) {
            // remove existing select interactions
            map.removeInteraction(selectControl.control)
            if (eventKeys.length) {
                eventKeys.forEach(eventKey => unByKey(eventKey))
            }

            // add select interactions to each layer
            map.addInteraction(selectControl.control)
            const keys = layers.reduce((acc, layer) => {
                const source = layer.getSource()
                const eventKey = selectControl.on(source, sharedPageKey, setSharedPageId, layer)
                return [...acc, ...eventKey]
            }, [])
            setEventKeys(keys)
            
            // add layers
            layers.map(layer => {
                map.addLayer(layer)
                return null
            })
            // set view to minimum extent that
            // fits all the data
            const fit = getMinimumLayerExtent(layers)
            map.getView().fit(fit, { size: map.getSize(), padding: [10, 10, 10, 10]})
            // set the zoom to extent toggle to the right size
            map.getControls().getArray().find(x => x instanceof ZoomToExtent ).extent = map.getView().calculateExtent()
        }
    }, [map, layers, setSharedPageId, sharedPageKey])

    // zoom to new feature when selected from table
    useEffect(() => {
        // remove all previously selected features
        layers.map(layer => layer.getSource().getFeatures().forEach(feature => feature.set('selected', 0)))
        if (sharedPageKey && sharedPageId) {

            // add new selection to feature layer
            layers.map(layer => layer.getSource().getFeatures().forEach(feature => {
                const featureSharedId = feature.get(sharedPageKey)

                if (featureSharedId === sharedPageId) {
                    // set the selected value to true
                    feature.set('selected', 1)
                    
                    // thl 2021.12.12 - remove "zoom to feature" on click
                    // // and zoom to fit the map around the selected feature
                    // const featurePoint = feature.getGeometry()
                    // map.getView().fit(featurePoint, {padding: [10, 10, 10, 10], duration: 500, maxZoom: 15})
                }
                return null
            }))
            map.render() 
        }
    }, [ map, layers, sharedPageKey, sharedPageId])

    
    useEffect(() => {
        if (map && legendState.length) {
            // set visibility when legendState changes
            map.getLayers().getArray()
                .filter(x => !!x.get('name')) // filter out layers without names
                .forEach(x => {
                    x.setVisible(legendState.find(y => y.layerName === x.get('name')).visible)
                })
        }
    }, [legendState, map])

    // when the legend comes in, recalculate the extent
    useEffect(() => {
        if (map && layers && mapRef) {
            if (mapHeightRef.current !== mapRef.current?.clientHeight) {
                mapHeightRef.current = mapRef.current.clientHeight
                map.setSize(mapRef.current.clientWidth, mapRef.current.clientHeight)
                map.updateSize()
                // set view to minimum extent that
                // fits all the data
                const fit = getMinimumLayerExtent(layers)
                map.getView().fit(fit, { size: map.getSize(), padding: [10, 10, 10, 10]})
                // set the zoom to extent toggle to the right size
                map.getControls().getArray().find(x => x instanceof ZoomToExtent ).extent = map.getView().calculateExtent()
            }
        }
    }, [map, layers, legendState, mapRef.current?.clientHeight])

    if (loading) {
        return null
    }

    return (
        <div className="mapFlexWrapper">
            <MapLegend />
            <div className="mapWrapper">
                <div
                    id="map"
                    ref={mapRef}
                    className={`mapContainer ${loading ? 'is-hidden' : ''}`}
                ></div>
                <MapTooltip
                    {...tooltipState}
                    tooltipData={getTooltipDataFromFeature(tooltipState)}
                />
            </div>
        </div>
    )
})

const FeatureMapWrapper = () => {
    const {
        loading,
        widgetData: widget,
    } = useContext(WidgetDataContext)
    const [layers, setLayers] = useState([])

    useEffect(() => {
        if (widget && widget.Data) {
            const {Data: data, WidgetOptions: options, SharedKey: sharedKey} = widget
            setLayers(getLayers(data, options, sharedKey))
        }
    }, [widget])
    
    return (
        <MapContextProvider data={layers} legendFunction={getLegendDataFromLayers}>
            <FeatureMap layers={layers} loading={loading} />
        </MapContextProvider>
    )
}

export default FeatureMapWrapper